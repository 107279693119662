.brand-logo {
  font-family: "Ubuntu", serif;
  font-size: 2rem;
  text-transform: none;
}

.flex-end {
  align-items: flex-end;
}
.flex-start {
  align-items: flex-start;
}
